<template>
	<div class="typingRecord">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">打单时间: </label>
				<el-date-picker type="datetime" placeholder="开始时间" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="结束时间" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions'></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type='primary' style="width:90px;" @click="filterFun">查询</el-button>
			</div> 
		</div>

		<!-- 表格 -->
		<!-- :row-key="rowKey" -->
		<el-table :data="groupData"  style="width: 100%;" v-loading="loading" ref="multipleTable">
			<el-table-column prop="AddTime" label="操作时间"></el-table-column>
			<el-table-column prop="OperatorName" label="操作人"></el-table-column>
			<el-table-column prop="OrderCount" label="打单数量"></el-table-column>
			<el-table-column prop="PushCount" label="成功打单数量"></el-table-column>
			<el-table-column prop="SendCount" label="成功发货数量"></el-table-column>
			<el-table-column prop="AddTime" label="状态">
				<template slot-scope="scope">
					<span v-if="scope.row.State ==0">打单中</span>
					<span v-if="scope.row.State ==1">打单完成</span>
					<span v-if="scope.row.State ==2">发货中</span>
					<span v-if="scope.row.State ==3">发货完成</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="300">
				<template slot-scope="scope">
					<span v-if="scope.row.State == 0||scope.row.State == 2" style="color:#000;margin-right:15px;cursor: pointer;">报表生成中</span>
					<el-button v-if="scope.row.State == 0" type="text" @click="suspendOrder(scope.row)">中断打单</el-button>
					<el-button v-if="scope.row.State != 0" type="text" @click="downOrder(scope.row)">下载打单报表</el-button>
					<el-button v-if="scope.row.State != 0" type="text" @click="downShopDetail(scope.row)">下载商品明细</el-button>
					<el-button v-if="scope.row.State == 1&&scope.row.PushCount>0" type="text" @click="sureSend(scope.row)">确认发货</el-button>
					<el-button v-if="scope.row.State == 3" type="text" @click="downSendOrder(scope.row)">下载发货报表</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: right;">
			<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	export default {
		data() {
			return {
				starTime: '',
				endTime: '',
				groupData: [{
					id: 0
				}],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				exportUrl: config.EXPORT_URL,
			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.starTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			}
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			// 获取列表数据
			async getList() {
				try {
					this.loading = true
					let data = {
						StartTime : this.starTime,
						EndTime : this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await apiList.assistantRecordIndex(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results|| [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			filterFun() {
				this.currentPage = 1
				this.getList()
			},
			// rowKey(row) {
			// 	return row.id
			// },
			// 中断打单
			async suspendOrder(record){
				try{
					let result  = await apiList.assistantRecordStop({
						Id:record.Id
					})
					if(result.IsSuccess){
						this.$message({
							showClose: true,
							type:'success',
							message:'中断成功'
						})
					}
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}
				
			},
			// 确认发货
			async sureSend(record){
				try{
					let result = await apiList.assistantRecordSend({
						Id:record.Id
					})
					if(result.IsSuccess){
						this.$message({
							showClose: true,
							type:'success',
							message:'发货成功'
						})
						this.getList()
					}else{
						this.$message({
							showClose: true,
							type:'error',
							message:'当前正在发货，请等待本次发货结束'
						})
					}
				}catch(e){
					console.log(e)
				}finally{
					
				}
			},
			// 下载打单报表
			downOrder(record){
				let url = this.exportUrl + '/pc/wx-express-assistant-push-record/download-push?Id=' + record.Id
				window.open(url)
				
			},
			downShopDetail(record){
				let url = this.exportUrl + '/pc/wx-express-assistant-push-record/download-push-order-detail?Id=' + record.Id
				window.open(url)
			},
				
			// 下载发货报表
			downSendOrder(record){
				let url = this.exportUrl + '/pc/wx-express-assistant-push-record/download-send?Id=' + record.Id
				window.open(url)
				
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},


		}
	}
</script>

<style lamg="less" scoped>
	.typingRecord {
		padding: 10px;
		background: #fff;
	}
</style>
